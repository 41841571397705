<template>
  <div>
    <app-card>
      <template #body>
        <app-table request-url="/api/companies/table" :fields="fields">
          <template #cell(name)="data">
            <router-link :to="{name: 'updateCompany', params: {id: data.item.id}}">
              {{ data.value }}
            </router-link>
          </template>
          <template #cell(email)="data">{{ data.value || '-' }}</template>
          <template #cell(address)="data">{{ data.value || '-' }}</template>
          <template #cell(phone)="data">{{ data.value || '-' }}</template>
        </app-table>
      </template>
    </app-card>

    <portal to="subheader-toolbar">
      <router-link :to="{name: 'createCompany'}" class="btn btn-transparent-white">
        {{ $t('btn.create_company') }}
      </router-link>
    </portal>
  </div>
</template>

<script>
import AppCard from '@/components/AppCard';
import AppTable from '@/components/lists/AppTable';

export default {
  components: {
    AppCard,
    AppTable
  },
  data() {
    return {
      fields: [
        {key: 'name', label: this.$t('label.title')},
        {key: 'email', label: this.$t('label.email')},
        {key: 'phone', label: this.$t('label.phone')},
        {key: 'address', label: this.$t('label.address')},
        {key: 'created_at', label: this.$t('label.created_at')}
      ]
    }
  },
  methods: {

  }
}
</script>